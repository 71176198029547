export default {
	init() {
		const $ = jQuery
	},
	finalize() {
		const $ = jQuery

		const activeFilters = {
			territory: false,
			product: false
		}

		// On filter

		$('.people__filters .select__wrapper').on('select', function () {
			let select = $(this)
			let key = select.data('key').toLowerCase()
			let value = select.data('value') ? select.data('value').toLowerCase() : null
			let otherKey = select.siblings('.select__wrapper').data('key').toLowerCase()
			let otherValue = select.siblings('.select__wrapper').data('value')
				? $(this).siblings('.select__wrapper').data('value').toLowerCase()
				: null

			if (activeFilters[key] && value) {
				$(`.people-card`).fadeOut()
				if (activeFilters[otherKey]) {
					$(`.people-card[data-person-${key}*="${value}"][data-person-${otherKey}*="${otherValue}"]`).fadeIn()
					activeFilters[key] = true
				} else {
					$(`.people-card[data-person-${key}*="${value}"]`).fadeIn()
					activeFilters[key] = false
				}
			} else if (activeFilters[key] && !value) {
				if (activeFilters[otherKey]) {
					$(`.people-card`).fadeOut()
					$(`.people-card[data-person-${otherKey}*="${otherValue}"]`).fadeIn()
				} else {
					$(`.people-card`).fadeIn()
				}
				activeFilters[key] = false
			} else if (!activeFilters[otherKey]) {
				$(`.people-card:not([data-person-${key}*="${value}"])`).fadeOut()
				$(`.people-card[data-person-${key}*="${value}"]`).fadeIn()
				activeFilters[key] = true
			} else {
				$(`.people-card:not([data-person-${key}*="${value}"])`).fadeOut()
				activeFilters[key] = true
			}
		})

		$('.people__filters #filter-all').on('click', function () {
			$('.people__filters .select__wrapper').removeData('value').removeClass('selected').removeClass('focused')
			$('.people__filters .select__wrapper li').removeClass('selected')
			$('.people__filters input').val('')
			$('.people-card').fadeIn()
		})

		$('.people__filters input').on('keyup', function (e) {
			let key = e.target.value.toLowerCase().trim()
			$(`.people-card`).hide()
			let results = $(`.people-card`).filter(function () {
				let person = $(this)
				return (
					person.data('person-fname').toLowerCase().trim().includes(key) ||
					person.data('person-lname').toLowerCase().trim().includes(key) ||
					person.data('person-degree').toLowerCase().trim().includes(key) ||
					person.data('person-position').toLowerCase().trim().includes(key) ||
					person.data('person-branch').toLowerCase().trim().includes(key) ||
					person.data('person-territory-names').toLowerCase().trim().includes(key) ||
					person.data('person-product-names').toLowerCase().trim().includes(key)
				)
			})
			results.show()
		})
	}
}
