import Glide from '@glidejs/glide'

export default {
	init() {
		const $ = jQuery
	},
	finalize() {
		const $ = jQuery

		// Hero slider

		const heroSlider = document.querySelectorAll('.hero__slider')
		for (let i = 0; i < heroSlider.length; i++) {
			const glide = new Glide(heroSlider[i], {
				type: 'slider',
				gap: 0,
				animationDuration: 500,
				animationTimingFunc: 'ease-in-out',
				autoplay: 3000
			})
			glide.mount()

			glide.on('run', function () {
				// Change text slider
				$('#hero .hero__text .slide-text[data-slide-count=' + glide.index + ']')
					.siblings()
					.each(function () {
						$(this).css({
							opacity: 0
						})
					})
				$('#hero .hero__text .slide-text[data-slide-count=' + glide.index + ']').css({
					opacity: 1
				})

				// Change glide bullet
				$('#hero .glide__bullet[data-glide-dir="=' + glide.index + '"]')
					.addClass('glide__bullet--active')
					.siblings()
					.each(function () {
						$(this).removeClass('glide__bullet--active')
					})
			})

			$('body').on('click', '#hero .glide__bullet', function () {
				const index = parseInt($(this).data('glide-dir').substring(1))
				glide.go(`=${index}`)
			})
		}

		// Products on click

		// Set product info/ask answer container height
		$(window).on('load', function () {
			let productHeight = 0
			let askHeight = 0
			$('.product__info').each(function () {
				if ($(this).outerHeight() > productHeight) {
					productHeight = $(this).outerHeight()
				}
			})
			$('.product__infos').css('min-height', `${productHeight}px`)

			$('.ask__answers').each(function () {
				if ($(this).outerHeight() > askHeight) {
					askHeight = $(this).outerHeight()
				}
			})
			$('.ask__answers').css('min-height', `${askHeight}px`)
		})

		$('body').on('click', '.product__title', function () {
			let id = $(this).data('product-id')
			let title = $(this)
			title
				.addClass('product__title--active')
				.siblings()
				.each(function () {
					$(this).removeClass('product__title--active')
				})
			title
				.parents('#products')
				.find('.product__info[data-product-id=' + id + ']')
				.siblings()
				.each(function () {
					$(this).removeClass('product__info--active')
				})
			setTimeout(function () {
				title
					.parents('#products')
					.find('.product__info[data-product-id=' + id + ']')
					.addClass('product__info--active')
			}, 500)
		})

		// Ask us on click

		$('body').on('click', '.ask__question', function () {
			let id = $(this).data('ask-id')
			let title = $(this)
			title
				.addClass('ask__question--active')
				.siblings()
				.each(function () {
					$(this).removeClass('ask__question--active')
				})
			title
				.parents('#ask')
				.find('.ask__answer[data-ask-id=' + id + ']')
				.siblings()
				.each(function () {
					$(this).removeClass('ask__answer--active')
				})
			setTimeout(function () {
				title
					.parents('#ask')
					.find('.ask__answer[data-ask-id=' + id + ']')
					.addClass('ask__answer--active')
			}, 500)
		})
	}
}
