import Router from './utils/Router'
import common from './common'
import home from './home'
import pageContact from './pageContact'
import pagePeople from './pagePeople'

const routes = new Router({
	common,
	home,
	pagePeople,
	pageContact
})

;(function ($) {
	routes.loadEvents()
})(jQuery)
