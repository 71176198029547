export default {
	init() {
		const $ = jQuery

		// Inline svg
		function imgToSvg() {
			$('img.inline-svg').each((img, e) => {
				const $img = $(e)
				const imgId = $img.attr('id')
				const imgClass = $img.attr('class')
				const imgUrl = $img.attr('src')
				const imgWidth = $img.attr('width')
				const imgHeight = $img.attr('height')

				$.get(
					imgUrl,
					(data) => {
						let $svg = $(data).find('svg')
						if (typeof $imgId !== 'undefined') {
							$svg = $svg.attr('id', imgId)
						}
						if (typeof $imgClass !== 'undefined') {
							$svg = $svg.attr('class', $imgClass)
						}
						$svg = $svg.removeAttr('xmlns:a')
						if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
							$svg.attr(`viewBox 0 0 ${$svg.attr('height')} ${$svg.attr('width')}`)
						}
						$svg.attr('width', imgWidth)
						$svg.attr('height', imgHeight)
						$img.replaceWith($svg)
					},
					'xml'
				)
			})
		}
		imgToSvg()
	},
	finalize() {
		const $ = jQuery

		// Custom select box

		$('body').on('click', '.select__wrapper', function (e) {
			e.stopPropagation()
			$(this)
				.removeClass('selected')
				.toggleClass('focused')
				.siblings()
				.each(function () {
					$(this).removeClass('focused')
				})
		})

		$('body').on('click', '.select__wrapper li', function (e) {
			e.stopPropagation()
			let value = $(this).data('value')
			let parent = $(this).parents('.select__wrapper')
			$(this)
				.toggleClass('selected')
				.siblings()
				.each(function () {
					$(this).removeClass('selected')
				})
			if ($(this).hasClass('selected')) {
				parent.data('value', value)
				parent.removeClass('focused').addClass('selected')
			} else {
				parent.removeData('value')
				parent.removeClass('focused').removeClass('selected')
			}
			parent.trigger('select')
		})

		// Expand header menu item

		$('body').on(
			'click',
			'.menu .menu-item-has-children > button, .menu .menu-item-has-children > a',
			function (e) {
				e.preventDefault()
				let parent = $(this).parents('.menu-item-has-children')
				parent.toggleClass('open')
			}
		)

		$('body').on('click', '.header__hamburger', function () {
			let nav = $(this).parents('.header').find('.header__menu')
			$(this).toggleClass('open')
			nav.toggleClass('open')
		})

		// Add `has-break` class if static heading contains line break in desktop layout

		if ($('#static-heading').length) {
			const heading = $('#static-heading h2, #static-heading h3')
			if (heading.find('br').length) {
				$('#static-heading').addClass('has-break')
			}
		}

		// Add class when scrolled into view

		const getElementsInArea = (function (docElm) {
			let viewportHeight = docElm.clientHeight

			return function (e, opts) {
				const found = []

				if (e && e.type == 'resize') {
					viewportHeight = docElm.clientHeight
				}

				opts.elements.forEach((elm, i) => {
					const pos = elm.getBoundingClientRect(),
						topPerc = (pos.top / viewportHeight) * 100,
						bottomPerc = (pos.bottom / viewportHeight) * 100,
						middle = (topPerc + bottomPerc) / 2,
						inViewport = middle > opts.zone[1] && middle < 100 - opts.zone[1]

					if (inViewport) {
						elm.classList.add(opts.markedClass)
						document.querySelectorAll('.focus-on-scroll').forEach((sibling, i) => {
							if (elm != sibling) {
								sibling.classList.remove(opts.markedClass)
							}
						})
						found.push(elm)
					}
				})
			}
		})(document.documentElement)

		const focusOnScrollResize = (e) => {
			getElementsInArea(e, {
				elements: document.querySelectorAll('.focus-on-scroll'),
				markedClass: 'focused',
				zone: [40, 40]
			})
		}

		const focusOnLoad = (e) => {
			getElementsInArea(e, {
				elements: document.querySelectorAll('.focus-on-load'),
				markedClass: 'focused',
				zone: [0, 0]
			})
		}

		window.addEventListener('scroll', focusOnScrollResize)
		window.addEventListener('resize', focusOnScrollResize)
		window.addEventListener('load', focusOnLoad)

		// Change header title on scroll

		if ($('.section__title--rotating').length > 0) {
			$(window).on('scroll', function () {
				let scrollTop = $(window).scrollTop()
				let allSectionTitles = $('.section__title--rotating')
				let headerLogo = $('.header .header__logo--text')
				let headerHeight = $('.header').outerHeight()
				let rotatingTitles = $('header .rotating-title')

				allSectionTitles.each(function (index, value) {
					let sectionTitle = $(this)
					let sectionName = sectionTitle.data('section')
					let sectionTitleOffset = sectionTitle.offset().top - scrollTop - headerHeight

					if (sectionTitleOffset < 0) {
						rotatingTitles.each(function (indexTitle, value) {
							let title = $(this)
							if (sectionName === title.data('title')) {
								headerLogo.css('top', `${(indexTitle + 1) * -100}%`)
							}
						})
					} else if (index === 0 && sectionTitleOffset > 0) {
						headerLogo.attr('style', '')
					}
				})
			})
		}

		// Scroll to top

		$('.scroll-to-top').on('click', function () {
			document.body.scrollTop = 0
			document.documentElement.scrollTop = 0
		})

		// People card on click

		$('.people-card > .people-card__thumbnail, .people-card > .people-card__details').on('click', function () {
			const card = $(this).parent()
			$('.people-card__modal').removeClass('open')
			card.find('.people-card__modal').toggleClass('open')
			if (card.find('.people-card__modal').hasClass('open')) {
				$('body').addClass('lock-scroll')
			} else {
				$('body').removeClass('lock-scroll')
			}
		})

		$('.people-card .modal__close').on('click', function () {
			const modal = $(this).parents('.people-card__modal')
			modal.toggleClass('open')
			$('body').removeClass('lock-scroll')
		})

		// Remove scroll lock from body on resize

		$(window).on('resize', function () {
			if (!$('.people-card__modal.open').length > 0) {
				$('body').removeClass('lock-scroll')
			}
		})
	}
}
